import SuperPage from 'navigation/SuperPage'
import scroll from 'core/scroll'
import router from '../../core/router'

class Project extends SuperPage {
  constructor (el) {
    super(...arguments)

    this.back = el.querySelector('.project__back')
    this.banner = el.querySelector('.project__banner img')
    this.imagesToLoad = [this.banner]
    this.toggleEvents()
  }

  show () {
    this.resize()
    super.show(...arguments)
  }

  scroll = () => {
    const scrollTop = scroll.scrollTop()

    if (scrollTop < this.bannerHeight)
      this.banner.style.transform = `translateY(${scrollTop / 2}px)`

    const headerVisible = scrollTop > (this.bannerHeight / 2)

    if (headerVisible !== this.headerVisible) {
      this.headerVisible = headerVisible
      this.header.el.classList.toggle('visible', headerVisible)
    }
  }

  handleBack = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!router.lastPage())
      router.navigate(event.currentTarget.getAttribute('href'))
    else
      window.history.back()
  }

  toggleEvents (add = true) {
    scroll.instance()[add ? 'on' : 'off'](this.scroll)
    this.back[add ? 'addEventListener' : 'removeEventListener']('click', this.handleBack)
  }

  flush () {
    super.flush()
    this.toggleEvents(false)
  }

  resize () {
    super.resize()
    this.bannerHeight = this.banner.offsetHeight
  }
}

Project.pageName = 'Project'

export default Project
