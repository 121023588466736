import scroll from 'core/scroll'
import resize from 'helpers/resize'
import each from 'lodash/each'
import anime from 'animejs'

export default class Header {
  constructor (el) {
    this.el = el
    scroll.instance().on(this.scroll)

    const button = this.el.querySelector('.spacer')
    button.addEventListener('click', this.toggle)
  }

  barVisible = false
  disabledFilter = false

  toggle = () => {
    this.el.classList.toggle('opened')
  }

  scroll = () => {
    const { scrollTop } = scroll
    const needBarVisible = scrollTop() > 250

    if (this.disabledFilter) return

    if (needBarVisible !== this.barVisible) {
      this.el.classList.toggle('bar-visible', needBarVisible)
      this.barVisible = needBarVisible
    }
  }

  disableFilter () {
    const filters = this.el.querySelectorAll('.page__filter li')
    each(filters, filter => filter.classList.remove('current'))
    this.el.classList.toggle('bar-visible', false)
    this.disabledFilter = true
  }

  show (pageName) {
    if (resize.mq.tabletPortrait()) return
    const base = {
      duration: 400,
      delay: (el, i) => i * 50,
      opacity: {
        value: [0, 1],
        easing: 'linear'
      },
      translateY: [20, 0],
      easing: 'easeOutSine'
    }


    const lis = this.el.querySelectorAll('.header__inner li')
    const footerLis = document.querySelectorAll('footer li a')

    const timeline = anime.timeline()

    timeline.add(
      Object.assign(
        {
          targets: lis
        },
        base
      ),
      0
    )

    timeline.add(
      Object.assign(
        {
          targets: footerLis
        },
        base
      ), 0
    )

    return timeline.finished
  }

  showMobile (liBase) {
    const lis = this.el.querySelectorAll('.menu li')
    const filters = this.el.querySelector('.page__filter')
    const footer = document.querySelector('footer')

    const translateY = ['100%', '0%']

    anime(
      Object.assign(
        {
          targets: lis
        },
        liBase
      )
    )

    const base = {
      duration: 400,
      easing: 'easeOutSine'
    }

    filters && (filters.style.opacity = 0)
    const timeline = anime.timeline(base)

    timeline.add(
      {
        targets: footer,
        translateY
      },
      400
    )

    filters &&
      timeline.add({
        targets: filters,
        translateY,
        changeBegin: () => (filters.style.opacity = 1)
      })

    return timeline.finished
  }

  hide () {
    if (resize.mq.tabletPortrait()) return

    const base = {
      opacity: {
        value: [1, 0],
        easing: 'linear'
      },
      translateY: [0, -20],
      duration: 400,
      easing: 'easeInSine',
      delay: (el, i) => i * 50
    }

    const lis = Array.from(this.el.querySelectorAll('li'))
    // lis.shift()
    const footerLis = document.querySelectorAll('footer li a')

    anime(
      Object.assign(
        {
          targets: lis
        },
        base
      )
    )

    return anime(
      Object.assign(
        {
          targets: footerLis
        },
        base
      )
    ).finished
  }

  hideMobile (liBase) {
    const lis = this.el.querySelectorAll('.menu li')
    const filters = this.el.querySelector('.page__filter')
    const footer = document.querySelector('footer')

    const translateY = ['0%', '100%']

    anime(
      Object.assign(
        {
          targets: lis
        },
        liBase
      )
    )

    const base = {
      duration: 200,
      easing: 'easeInSine'
    }

    const timeline = anime.timeline(base)

    filters &&
      timeline.add(
        {
          targets: filters,
          translateY: ['0%', '200%']
          // complete: () => (filters.style.opacity = 0)
        },
        0
      )

    timeline.add(
      {
        targets: footer,
        translateY
      },
      0
    )

    return timeline.finished
  }

  flush () {
    const button = this.el.querySelector('.spacer')
    button.removeEventListener('click', this.toggle)
    scroll.instance().off(this.scroll)
  }

  resize () {}
}
