import VirtualScroll from 'virtual-scroll'
import Emitter from 'tiny-emitter'
import bowser from 'bowser'
import 'scrolling-element'

import anime from 'animejs'

let instance
let scrollTop = document.scrollingElement.scrollTop
let scrollBeforeLock = false

const desktop = !bowser.mobile && !bowser.tablet
const virtual = desktop && !bowser.windows
const emitter = new Emitter()
const multiplier = bowser.firefox ? 0.4 : bowser.windows ? 1 : 0.15

const init = () => {
  instance = new VirtualScroll({
    el: document.scrollingElement,
    mouseMultiplier: multiplier,
    firefoxMultiplier: 30 / multiplier,
    passive: false
  })

  instance.on(scroll)
  window.addEventListener('scroll', pageScroll)
}

const lock = () => {
  scrollBeforeLock = scrollTop
  document.scrollingElement.classList.add('scroll-lock')
}

const unlock = () => {
  document.scrollingElement.classList.remove('scroll-lock')
  if (scrollBeforeLock) document.scrollingElement.scrollTop = scrollBeforeLock
  scrollBeforeLock = false
}

const pageScroll = (event) => {
  const _scrollTop = document.scrollingElement.scrollTop
  if (_scrollTop === scrollTop) return

  testScroll(_scrollTop)
  scrollTop = _scrollTop
  emitter.emit('', event)
}

const scroll = (event) => {
  if (virtual && event) {
    event.originalEvent.preventDefault()
    document.scrollingElement.scrollTop -= event.deltaY
  }

  if (virtual) {
    const _scrollTop = document.scrollingElement.scrollTop
    testScroll(_scrollTop)
    scrollTop = _scrollTop
  }

  if (desktop && event) emitter.emit('', event)
  emitter.emit('virtual', event)
}

let startScrollDown = false
let hasScrollDown = false
let lastScrollTop = Infinity
const scrollClassname = 'scroll-down'
const topOffset = 50
const offset = 50

const testScroll = (newScrollTop) => {
  if (newScrollTop === scrollTop) return
  if (newScrollTop > Math.max(0, scrollTop)) scrollDown(newScrollTop)
  else scrollUp(newScrollTop)
}

const scrollDown = (newScrollTop) => {
  if (!startScrollDown) {
    startScrollDown = true
    lastScrollTop = newScrollTop
  }

  if (hasScrollDown || Math.abs(lastScrollTop - newScrollTop) < offset) return
  document.body.classList.add(scrollClassname)
  lastScrollTop = newScrollTop
  hasScrollDown = true
}

const scrollUp = (newScrollTop) => {
  if (startScrollDown) {
    startScrollDown = false
    lastScrollTop = newScrollTop
  }

  if (!hasScrollDown || (Math.abs(lastScrollTop - newScrollTop) < offset && newScrollTop > topOffset)) return
  document.body.classList.remove(scrollClassname)
  hasScrollDown = false
}

const scrollTo = (scrollTop, duration = 400, easing = 'easeOutSine') => {
  anime({
    targets: document.scrollingElement,
    scrollTop,
    duration,
    easing,
    update: () => pageScroll({})
  })
}

export default {
  init,
  lock,
  unlock,
  scrollTo,
  instance: () => ({
    on: (cb) => emitter.on('', cb),
    off: (cb) => emitter.off('', cb)
  }),
  virtual: () => ({
    on: (cb) => emitter.on('virtual', cb),
    off: (cb) => emitter.off('virtual', cb)
  }),
  scrollTop: () => scrollTop,
  reset: () => scroll() && unlock()
}
