import PageManager from 'navigation/SuperPageManager'

import Page from 'navigation/SuperPage'
import About from './about/About'
import Project from './project/Project'
import Projects from './projects/Projects'

import scroll from 'core/scroll'

import resize from 'helpers/resize'

class Main {
  constructor (el) {
    this.el = el
    this.createVirtualScroll()
    this.createPageManager()

    resize.root = document.querySelector('.container')
    this.listenResize()
  }

  createPageManager () {
    const panels = this.el.querySelectorAll('.panel')

    this.pageManager = new PageManager(
      document.querySelector('.container'),
      '.page',
      {
        'categories/*': Projects,
        'projects': Projects,
        'projects/*': Project,
        'about': About,
        'contact': About,
        '': Projects,
        '*': Page
      },
      { panels }
    )
  }

  createVirtualScroll () {
    scroll.init()
  }

  listenResize () {
    resize.add(this)
    this.resize()
  }

  resize = () => {
    this.pageManager.resize()
  }
}

export default Main
