import SuperPage from 'navigation/SuperPage'
import each from 'lodash/each'
import anime from 'animejs'

import bowser from 'bowser'

class Projects extends SuperPage {
  constructor (el) {
    super(...arguments)

    this.links = el.querySelectorAll('.projects__item a')
    this.description = el.querySelector('.projects__description')
    this.descriptionInner = this.description.querySelector('.projects__description-inner')
    this.button = this.description.querySelector('.projects__description-toggle')
    this.toggleEvents()
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    each(this.links, link => link[method]('click', this.onItemClick))
    this.button[method]('click', this.toggleDescription)

    if (bowser.desktop) {
      each(this.links, link => link[method]('mouseover', this.onItemOver))
      each(this.links, link => link[method]('mouseout', this.onItemOut))
    }
  }

  toggleDescription = event => {
    this.toggled = !this.toggled
    this.button.classList.toggle('toggled', this.toggled)
    const height = this.descriptionInner.offsetHeight
    anime({
      targets: this.description,
      height: !this.toggled ? [height, 0] : [0, height],
      easing: 'easeInOutSine',
      duration: 400
    })
  }

  onItemClick = event => {
    // const target = event.currentTarget
    // this.colors = [
    //   target.getAttribute('data-secondary'),
    //   target.getAttribute('data-primary')
    // ]
  }

  zIndex = 10
  opened = 0

  onItemOver = event => {
    console.log('onItemOVer')
    const target = event.target
    const caption = target.querySelector('.projects__item-caption')
    const heigth = caption.offsetHeight

    anime.remove([target, caption])

    const timeline = anime.timeline({ easing: 'easeOutQuad', duration: 300 })
    timeline.add({ targets: target, translateY: -heigth / 2 }, 0)
    timeline.add({ targets: caption, translateY: heigth }, 0)

    this.zIndex++
    this.opened++
    target.parentNode.style.zIndex = this.zIndex

    this.queue = timeline.finished
  }

  onItemOut = event => {
    const target = event.currentTarget
    const caption = target.querySelector('.projects__item-caption')

    anime.remove([target, caption])

    const timeline = anime.timeline({ easing: 'easeOutQuad', duration: 150 })
    timeline.add({ targets: target, translateY: 0 }, 0)
    timeline.add({ targets: caption, translateY: 0 }, 0)

    this.queue = timeline.finished.then(() => {
      this.opened--
      if (this.opened === 0) this.zIndex = 10
      target.parentNode.style.zIndex = ''
      // this.zIndex--
    })
  }

  flush () {
    super.flush()
    this.toggleEvents(false)
  }

  resize () {
    super.resize()
  }
}

Projects.pageName = 'Projects'

export default Projects
