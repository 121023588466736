import PageManager from './PageManager'
import scroll from 'core/scroll'
import bowser from 'bowser'

class SuperPageManager extends PageManager {
  removePage () {
    super.removePage()
    scroll.reset()
  }

  extractPageFromXHR (el) {
    if (bowser.safari) {
      if (!this.tmpDiv) this.tmpDiv = document.createElement('div')
      this.tmpDiv.innerHTML = '' + el.innerHTML
      return super.extractPageFromXHR(this.tmpDiv)
    }
    return super.extractPageFromXHR(...arguments)
  }
}

export default SuperPageManager
