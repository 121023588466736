import SuperPage from 'navigation/SuperPage'
// import leaflet from 'leaflet'
// import { EsriProvider } from 'leaflet-geosearch'
// import 'leaflet/dist/leaflet.css'

class About extends SuperPage {
  constructor (el) {
    super(...arguments)

    this.toggleEvents()
  }

  toggleEvents (add = true) {
  }

  show () {
    super.show(...arguments)
    // this.initMap()
  }

  initMap () {
    const address = this.el.querySelector('.about__address').innerText

    this.map = leaflet.map(this.el.querySelector('.about__map'))

    leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}' + (leaflet.Browser.retina ? '@2x.png' : '.png'), {
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
      minZoom: 10,
      maxZoom: 20
    }).addTo(this.map)

    this.icon = leaflet.icon({
      iconUrl: '/assets/img/marker.png',
      iconSize: [50, 60],
      iconAnchor: [25, 60],
      popupAnchor: [0, -65]
    })

    this.provider = new EsriProvider()
    this.provider.search({ query: address })
      .then((results) => {
        if (!results[0]) return
        const point = [results[0].y, results[0].x]
        this.marker = leaflet.marker(point, { icon: this.icon }).addTo(this.map)
        this.map.setView(point, 15)
        this.marker.bindPopup(address)
      })
  }

  flush () {
    super.flush()
    this.toggleEvents(false)
    // this.map.off()
    // this.map.remove()
    // this.marker.remove()
    // delete this.map
    // delete this.icon
    // delete this.marker
    // delete this.provider
  }

  resize () {
    super.resize()
  }
}

About.pageName = 'About'

export default About
